@import (less) "modern-normalize";

@default-bg: #f4f4f4;
@default-fg: #333;

@card-bg: #fff;
@card-border: #e0e0e0;
@card-danger-border: #af0606;
@card-danger-bg: rgba(@card-danger-border, .3);
@card-danger-fg: darken(@card-danger-border, 10);
@ripe-fg: #24326a;
@highlight-fg: #dd6628;

@overlay-layer: 10;

:root {
    font-family: 'Public Sans', 'Open Sans', sans-serrif;
    font-size: 16px;
}

body {
    background-color: @default-bg;
    color: @default-fg;
}

a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        color: @highlight-fg;
        border-bottom: 1px solid @highlight-fg;
    }
}

article[role="page"] {
    & > header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        background-color: @card-bg;
        box-shadow: 0 4px 4px 0px rgba(#000, .3);
        padding: 10px 20px;

        .logo {
            a {
                border: none;
            }
            img {
                height: calc(10vmin - 20px);
                min-height: 20px;
                max-height: 60px
            }
        }

        .application {
            font-size: 1.5rem;
            margin: auto 0;
        }
    }

    & .content-wrapper {
        padding: 20px 0;
        margin: 0 auto;
        min-height: calc(100vh - 80px - 60px);

        & > aside[role="filter"] {
            display: none;
        }

        @media screen and (min-width: 1280px) {
            display: grid;
            grid-template-areas: "nav main filter";
            grid-template-columns: minmax(0,15rem) minmax(0,2.5fr) minmax(0,15rem);
            gap: 3rem;

            & > main {
                grid-area: main;
                max-width: 1280px;
            }

            & > aside[role="filter"] {
                display: block;
                grid-area: filter;
                max-width: 180px;
            }
        }
    }

    & > footer {
        color: @ripe-fg;
        border-top: 1px solid @card-border;
        min-height: 56px;
        padding: 20px;
        text-align: center;
        font-size: 0.8em;
    }
}

.card {
    background-color: @card-bg;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid @card-border;

    h1,h2,h3,h4,h5,h6 {
        font-size: 1.3rem;
        margin: 0;
    }

    &.danger {
        background-color: @card-danger-bg;
        border-color: @card-danger-border;
        color: @card-danger-fg;
    }
}

.graph {
    svg {
        max-width: 100%;
    }
}

.checkbox {
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 0 5px 35px;
    position: relative;
    transition: 0.3s;

    &:hover {
        color: @highlight-fg;
    }

    input[type="checkbox"] {
        appearance: none;
        cursor: pointer;

        &::after {
            position: absolute;
            left: 5px;
            top: 5px;
            height: 20px;
            width: 20px;
            margin-right: 10px;
            display: inline-block;
            opacity: 0.6;
            border: 1px solid @ripe-fg;
            transition: all .12s,border-color .08s;

            content: "";
        }

        &:checked::after {
            border-top-color: transparent;
            border-left-color: transparent;
            top: 5px;
            left: 10px;
            width: 10px;
            opacity: 1;
            transform: rotate(45deg);
        }
    }
}

.overlay {
    &::before {
        content: "";
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: rgba(#000, .3);
        position: absolute;
        left: 0;
        top: 0;
        z-index: @overlay-layer;
        overflow: hidden;
    }
}

.loading {
    width: 25vmin;
    height: 25vmin;
    position: fixed;
    left: calc((100vw - 25vmin) / 2);
    top: calc((100vh - 25vmin) / 2);
    padding-top: calc((25vmin - 20px) / 2);
    text-align: center;
    z-index: @overlay-layer + 1;
    border-radius: 15px;
    border: none;
    background-color: rgba(#333, .6);
    color: #f4f4f4;
    font-size: 1.5rem;
}
